import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";

const SubHeaderWrapper = styled.div`
  width: 100%;
`;

const SubHeader = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 1140px;
`;

interface HeaderProps {
  siteTitle: string;
}

const Header: React.FC<HeaderProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      etaLogo: file(relativePath: { eq: "SVGLogo.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <>
      <SubHeaderWrapper>
        <SubHeader>
          <div className={"container"} style={{ marginLeft: "1rem" }}>
            <img
              src={data && data["etaLogo"].publicURL}
              style={{
                width: "40%",
                padding: "2rem 2rem 2rem 0",
                minWidth: "255px",
              }}
              alt={"eta-metropol-logo"}
            />
          </div>
        </SubHeader>
      </SubHeaderWrapper>
    </>
  );
};

export default Header;

import styled, { createGlobalStyle } from "styled-components";
import { colors } from "./colors";

export const GlobalStyle = createGlobalStyle`
  //html {
  //  scroll-behavior: smooth;
  //}
  body {
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0;
    overflow-x: hidden;
  }
  h1 {
    font-family: 'Asap Condensed', sans-serif;
    color: ${colors.ihk_darkblue}
  }
  h2 {
    font-family: 'Asap Condensed', sans-serif;
    color: ${colors.ihk_darkblue}

  }
  h3 {
    font-family: 'Asap Condensed', sans-serif;
    color: ${colors.ihk_darkblue}

  }
  h4 {
    font-family: 'Asap Condensed', sans-serif;
    color: ${colors.ihk_darkblue}

  }

  @media only screen and (max-width: 675px) {
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1rem;
    }
    h4 {
      font-size: 1rem;
    }
  }

  a {
    text-decoration: none;
  }
  .icon{
    width: 100px;
    height: 100px;
    padding: 15px;
    filter: invert(72%) sepia(27%) saturate(490%) hue-rotate(40deg) brightness(100%) contrast(96%);
  }
  .container {
    //margin-bottom: 50px;
  }
  
  .companies-flexcontainer{
    display: flex;
    
    margin-bottom: 25px;
    
    .companies-img{
      flex: 100px 1 1;
    }
    .companies-text {
      flex: 300px 3 1;
    }    
  }
`;

export const GridWrapperSingleCol = styled.div`
  display: grid;
  grid-columns: 1;
  gap: 25px;
`;

export const GridWrapperMultiCol = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;

  @media only screen and (max-width: 990px) {
    grid-template-columns: 1fr;
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const GridImg = styled.div`
  display: flex;
  justify-content: center;
  flex: 20% 0 1;
  @media (max-width: 768px) {
    max-height: 150px;
    width: 150px;
  }
`;

export const RoundGridImg = styled(GridImg)`
  flex: 150px 0 0;
  height: 150px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
`;

export const GridTextBody = styled.div`
  flex: 66% 1 1;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactNode } from "react";

import Header from "./header";
import { GlobalStyle } from "../styles/GlobalStyles";
import "../../static/font.css";
import { Footer } from "./Footer";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Header siteTitle={"ETA-Metropol"} />
      <GlobalStyle />
      <div
        style={{
          margin: `0 auto`,
          paddingTop: "5rem",
          // maxWidth: 1000,
          padding: `0 1.0875rem 1.45rem`,
          overflowX: "hidden",
        }}
      >
        <main>{children}</main>
      </div>
      <Footer />
    </>
  );
};

export default Layout;

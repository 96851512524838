import React from "react";
import styled from "styled-components";
import { colors } from "../styles/colors";

const StFooter = styled.footer`
  background-color: ${colors.ihk_darkblue};
  color: white;
  padding: 1rem 0;
  position: relative;
  z-index: 99;
`;
const FooterInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  //max-width: 1140px;
  padding: 0 1.0875rem;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  font-family: "Asap Condensed", sans-serif;
`;
const RowContainerBottom = styled(RowContainer)`
  margin-bottom: 1rem;
  flex-wrap: wrap-reverse;
  font-family: "Source Sans Pro", sans-serif;
`;

const SocialMediaContainer = styled.div`
  display: flex;
`;

const StLink = styled.a`
  margin: 0 0 1rem 0;
  color: white;
  text-decoration: none;

  :hover {
    text-decoration: underline;
    text-underline: white;
  }
`;

const ResponsibilityContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 1rem;
  font-size: 0.8rem;

  @media only screen and (max-width: 699px) {
    width: 100%;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
`;

export const Footer: React.FC = () => {
  return (
    <StFooter id={"kontakt"}>
      <FooterInnerContainer className={"container"}>
        <RowContainer>
          <FooterColumn>
            <SocialMediaContainer>
              <StLink
                style={{ color: "white", marginRight: "3rem" }}
                href={
                  "https://www.darmstadt.ihk.de/internetauftritt/impr-2520832"
                }
                target="_blank"
                rel="noreferrer"
              >
                Impressum
              </StLink>
              <StLink
                style={{ color: "white" }}
                href={
                  "https://www.darmstadt.ihk.de/internetauftritt/datenschutz-2520830"
                }
                target="_blank"
                rel="noreferrer"
              >
                Datenschutzerklärung
              </StLink>
            </SocialMediaContainer>
          </FooterColumn>
        </RowContainer>
        <RowContainerBottom>
          <ResponsibilityContainer>
            © Industrie- und Handelskammer Darmstadt <br />
            Für Richtigkeit der in dieser Website enthaltenen Angaben können wir
            trotz sorgfältiger Prüfung keine Gewähr übernehmen. Bei den
            angelinkten externen Seiten handelt es sich ausschließlich um fremde
            Inhalte, für die wir keine Haftung übernehmen und deren Inhalt wir
            uns nicht zu Eigen machen. <br />
            Hinweis: Zur besseren Lesbarkeit verwenden wir die männliche Form.
            Darunter gefasst sind alle Geschlechter.
          </ResponsibilityContainer>
        </RowContainerBottom>
        <StLink
          style={{ color: "white", fontSize: "0.8rem" }}
          href={"https://www.cosee.biz/"}
          target="_blank"
          rel="noreferrer"
        >
          Site Design & Development by cosee GmbH
        </StLink>
      </FooterInnerContainer>
    </StFooter>
  );
};
